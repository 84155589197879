<template>
  <div id="divMyWalletDetailWrapper" class="wallet-detail-wrapper">
    <transition appear appear-active-class="fade-enter-active">
      <section class="section cards">
        <div class="section-header">
          <div class="section-header-content">
            <VueText sizeLevel="10" weightLevel="4">{{ pointFormatter(getCurrentPoints) }}</VueText
            ><VueText color="grey-30" sizeLevel="6">Puan</VueText>
          </div>
        </div>
      </section>
    </transition>
    <div class="gtm-ad-slot" id="div-gpt-ad-1587047396774-0"></div>
    <transition appear appear-active-class="fade-enter-active">
      <section @click="openFilter('year')" class="section year-select">
        <VueText sizeLevel="5" weightLevel="2" color="#79838e"
          >Yıl : {{ filterOptions.year.current }}</VueText
        ><VueIcon
          class="icon-caret"
          :iconName="icons.chevronDownAlt.name"
          :width="icons.chevronDownAlt.width"
          :height="icons.chevronDownAlt.height"
          color="#79838e"
        ></VueIcon>
      </section>
    </transition>
    <transition appear appear-active-class="fade-enter-active">
      <section class="section cards-table">
        <table>
          <thead>
            <tr>
              <th @click="openFilter('partner')">
                <VueText sizeLevel="3" weightLevel="2" color="#79838e">Partner</VueText
                ><VueIcon
                  :iconName="icons.chevronDownAlt.name"
                  :width="icons.chevronDownAlt.width"
                  :height="icons.chevronDownAlt.height"
                  color="#79838e"
                ></VueIcon>
              </th>
              <th @click="sortTable('date')">
                <VueText sizeLevel="3" weightLevel="2" color="#79838e">Tarih</VueText
                ><VueIcon
                  v-show="sortOptions.order == 'desc'"
                  :iconName="icons.chevronDownAlt.name"
                  :width="icons.chevronDownAlt.width"
                  :height="icons.chevronDownAlt.height"
                  color="#79838e"
                ></VueIcon>
                <VueIcon
                  v-show="sortOptions.order == 'asc'"
                  :iconName="icons.chevronUpAlt.name"
                  :width="icons.chevronUpAlt.width"
                  :height="icons.chevronUpAlt.height"
                  color="#79838e"
                ></VueIcon>
              </th>
              <th @click="openFilter('details')">
                <VueText sizeLevel="3" weightLevel="2" color="#79838e">İşlem</VueText
                ><VueIcon
                  :iconName="icons.chevronDownAlt.name"
                  :width="icons.chevronDownAlt.width"
                  :height="icons.chevronDownAlt.height"
                  color="#79838e"
                ></VueIcon>
              </th>
              <th>
                <VueText sizeLevel="3" weightLevel="2" color="#79838e">Tutar</VueText>
              </th>
            </tr>
          </thead>

          <tbody>
            <transition
              appear
              appear-active-class="fade-enter-active"
              v-for="(item, i) in filteredItems"
              :key="`log_${i}_${item.date}`"
            >
              <tr>
                <td>
                  <BrandLogo
                    class="cards-table-image"
                    :key="p.id"
                    v-for="p in item.partners"
                    :image="p.image"
                    :width="40"
                    :height="40"
                  />
                </td>
                <td class="cards-table-date">
                  <VueText sizeLevel="3" weightLevel="2">{{
                    item.date | toLocaleDateTimeString({ format: dateFormat })
                  }}</VueText>
                </td>
                <td class="cards-table-process" @click="showProcessInfo(item)">
                  <VueText sizeLevel="3" weightLevel="2">{{ item.details }}</VueText>
                  <VueIcon
                    class="icon-process"
                    v-if="item.processType.processTypeCode == 6"
                    :width="icons.info.width"
                    :height="icons.info.height"
                    :iconName="icons.info.name"
                  />
                </td>
                <td class="cards-table-amount" :class="[item.isPointDetail ? 'point' : '']">
                  <VueText
                    sizeLevel="4"
                    weightLevel="4"
                    color="green-10"
                    v-if="item.exchangePoints >= 0"
                    >+{{
                      `${pointFormatter(item.exchangePoints) + (!item.isPointDetail ? ' ₺' : '')}`
                    }}</VueText
                  >
                  <VueText sizeLevel="4" weightLevel="4" color="red-30" v-else>{{
                    `${pointFormatter(item.exchangePoints) + (!item.isPointDetail ? ' ₺' : '')}`
                  }}</VueText>
                  <VueIcon
                    v-if="item.isPointDetail"
                    :iconName="icons.points.name"
                    :width="icons.points.width"
                    :height="icons.points.height"
                    :color="item.exchangePoints >= 0 ? '#6dd400' : '#e02020'"
                  ></VueIcon>
                </td>
              </tr>
            </transition>
          </tbody>
        </table>
      </section>
    </transition>
    <transition name="slide">
      <BrandPickerModal
        refreshData
        :textPicker="true"
        :showModal="filterOptions.year.show"
        :textArray="getYears"
        filterType="year"
        @click="cancel"
        @getText="getFilteredItems"
      ></BrandPickerModal>
    </transition>
    <transition name="slide">
      <BrandPickerModal
        refreshData
        :textPicker="true"
        :showModal="filterOptions.partner.show"
        :textArray="filterOptions.partner.data"
        filterType="partner"
        @click="cancel"
        @getText="getFilteredItems"
      ></BrandPickerModal>
    </transition>
    <transition name="slide">
      <BrandPickerModal
        :textPicker="true"
        refreshData
        :showModal="filterOptions.details.show"
        :textArray="filterOptions.details.data"
        filterType="details"
        @click="cancel"
        @getText="getFilteredItems"
      ></BrandPickerModal>
    </transition>

    <BrandInfoModal :show.sync="processTypeModal.show">
      <div class="process-info-modal" v-if="processTypeModal.data">
        <h2>{{ processTypeModal.data.processTypeDescription }}</h2>
        <table>
          <thead>
            <tr>
              <th>Kriter</th>
              <th>Performans</th>
              <th>Puan Değeri</th>
              <th>Kazanılan Puan</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="crit in processTypeModal.data.calculatedCriterias"
              :key="crit.calculatedCriteriaIndex"
            >
              <td>{{ crit.calculatedCriteriaName }}</td>
              <td>{{ crit.calculatedCriteriaPerform }}</td>
              <td>{{ crit.calculatedCriteriaMaxPoint }}</td>
              <td>{{ crit.calculatedCriteriaPoint }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td>TOPLAM</td>
              <td>{{ processTypeModal.data.sumMaxPoint }}</td>
              <td>{{ processTypeModal.data.sumPoint }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </BrandInfoModal>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText';
import VueIcon from '@/components/shared/VueIcon/VueIcon';
import BrandPickerModal from '@/components/brand/Modals/BrandModal/BrandPickerModal.vue';
import BrandInfoModal from '@/components/brand/Modals/BrandModal/BrandInfoModal.vue';
import StorageProps from '@/mixins/storageProps.js';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';

import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { Finance } from '@/services/Api/index.js';
import dateUtils from '@/mixins/dateUtils';
import gtmUtils from '@/mixins/gtmUtils.js';
import { groupBy, filterBy, sortBy, sumBy } from '@/mixins/arrayUtils';
import { numberFormatter } from '@/utils/publicHelper';

export default {
  name: 'WalletsDetail',
  mixins: [StorageProps, dateUtils, gtmUtils],
  components: {
    VueText,
    VueIcon,
    BrandPickerModal,
    BrandInfoModal,
    BrandLogo,
  },
  data() {
    let defaultPartner = '';
    if (this.$route.query && this.$route.query.partner) {
      defaultPartner = this.$route.query.partner;
    }
    return {
      dateFormat: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      },
      pointLogs: [],
      sortOptions: { key: 'date', order: 'desc' },
      processTypeModal: { show: false, data: null },
      filterOptions: {
        year: { show: false, data: [], current: new Date().getFullYear() },
        details: { show: false, data: [] },
        partner: { show: false, data: [] },
        keys: { details: '', partner: defaultPartner },
      },
    };
  },
  beforeMount() {
    this.setTableData();
  },
  mounted() {
    this.pushAds('large');
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
    getCurrentPoints() {
      if (this.filterOptions.keys.partner && this.filterOptions.keys.partner !== 'Hepsi') {
        return sumBy(this.customerDetail.partners, 'point', {
          key: 'code',
          value: this.filterOptions.keys.partner,
        });
      } else {
        return this.customerPoints;
      }
    },
    filteredItems() {
      let filteredData = this.pointLogs;

      for (let [key, value] of Object.entries(this.filterOptions.keys)) {
        if (key == 'details' && value) {
          if (value != 'Hepsi') {
            filteredData = filterBy(filteredData, key, value);
          }
        }

        if (key == 'partner' && value) {
          if (value != 'Hepsi') {
            filteredData = filteredData.filter(
              item => item.partners.filter(x => x.partnerCode === value).length > 0,
            );
          }
        }
      }
      if (this.sortOptions.key) {
        filteredData = sortBy(filteredData, this.sortOptions.key, this.sortOptions.order);
      }

      return filteredData;
    },
    getYears() {
      let allYears = [];
      let nowYear = new Date().getFullYear();

      for (let i = nowYear; i > nowYear - 4; i--) {
        allYears.push(i.toString());
      }
      return allYears;
    },
  },
  methods: {
    pointFormatter(value) {
      return numberFormatter(value);
    },
    showProcessInfo(item) {
      if (item.processType.processTypeCode != 6) {
        return;
      }
      this.processTypeModal.data = Object.assign({}, item.processType);
      this.processTypeModal.show = true;
    },
    sortTable(key) {
      this.sortOptions.key = key;
      this.sortOptions.order = this.sortOptions.order == 'desc' ? 'asc' : 'desc';
    },
    setTableData() {
      Finance.getPointLogs(this.filterOptions.year.current).then(res => {
        if (res.data && res.data.Data && res.data.Data.result) {
          const {
            Data: { result },
          } = res.data;

          this.pointLogs = result;

          this.filterOptions.details.data = [
            'Hepsi',
            ...Object.keys(groupBy(this.pointLogs, 'details')),
          ];
          this.filterOptions.partner.data = ['Hepsi'];
          this.pointLogs.forEach(element => {
            element.partners.forEach(x => {
              if (!this.filterOptions.partner.data.includes(x.partnerCode)) {
                this.filterOptions.partner.data.push(x.partnerCode);
              }
            });
          });
        }
      });
    },

    openFilter(filterType) {
      this.filterOptions.details.show = false;
      this.filterOptions.year.show = false;
      this.filterOptions.partner.show = false;
      this.filterOptions[filterType].show = true;
      document.documentElement.style.overflow = 'hidden';
      this.setBottomBarStatus(false);
    },
    cancel() {
      this.filterOptions.details.show = false;
      this.filterOptions.year.show = false;
      this.filterOptions.partner.show = false;
      this.setBottomBarStatus(true);

      document.documentElement.style.overflow = 'auto';
    },
    getFilteredItems(value, id, filterType) {
      this.filterOptions[filterType].show = false;
      if (filterType == 'year') {
        this.filterOptions.year.current = value;
        this.setTableData();
      }
      this.setBottomBarStatus(true);
      this.filterOptions.keys[filterType] = value;
    },
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_animations.scss';
.wallet-detail-wrapper {
  padding-bottom: palette-space-level(30);
  .section {
    &.year-select {
      display: inline-flex;
      align-items: center;
      padding: palette-space-level(10) palette-space-level(15);
      border: 1px solid #b4c2d3b4;
      border-radius: palette-space-level(5);
      margin-bottom: palette-space-level(5);
      background-color: #f8f9f9;
      p {
        padding-right: palette-space-level('5');
      }
    }
    &.cards {
      padding: 0 palette-space-level('20') palette-space-level('10') palette-space-level('20');
    }

    &-header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: palette-space-level('30') palette-space-level('20');
      margin-right: -20px;
      margin-left: -20px;
      background-color: palette-color-level('grey', '10');

      &-content {
        display: flex;
        align-items: center;
        background: url('~@/assets/images/score.png') no-repeat left center;
        background-size: contain;
        padding-left: palette-space-level('35');

        p:last-child {
          margin-left: palette-space-level('5');
        }
      }
    }
  }

  .cards {
    &-list {
      $self: &;
      background-image: linear-gradient(
        315deg,
        palette-color-level('fushia', '20'),
        palette-color-level('yellow', '30')
      );
      padding: 2px;
      border-radius: palette-radius-level('4');
    }
    &-table {
      table {
        width: 100%;
        border-top: 1px solid palette-color-level('grey', '15');
        border-bottom: 1px solid palette-color-level('grey', '15');
      }

      thead {
        th {
          text-align: center;
          padding: palette-space-level('15') palette-space-level('10');
          background-color: palette-color-level('grey', '10');
          &:first-child {
            min-width: palette-space-level(90);
          }

          &:not(:last-child) {
            border-right: 1px solid palette-color-level('grey', '15');
          }
          p {
            position: relative;
            top: -5px;

            &:not(:last-child) {
              margin-right: palette-space-level('8');
            }
          }
          p,
          svg {
            display: inline-block;
          }
        }
      }
      tbody {
        width: 100%;
        td {
          border-top: 1px solid palette-color-level('grey', '15');
          padding: palette-space-level('10');
          &.cards-table-date {
            text-align: center;
          }
          .cards-table-image {
            &:not(:first-child) {
              margin-top: 5px;
            }
          }
          &.cards-table-amount {
            vertical-align: middle;
            text-align: center;
            white-space: nowrap;
            p,
            svg {
              vertical-align: middle;
              display: inline-block;
            }
            p {
              text-align: center;
            }
            &.point {
              padding-top: palette-space-level(15);
              padding-bottom: palette-space-level(15);
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                padding-left: palette-space-level(5);
              }
            }
          }
          &.cards-table-process {
            max-width: 90px;
            position: relative;
            .icon-process {
              position: absolute;
              top: palette-space-level('5');
              right: palette-space-level('5');
            }
          }
          &:not(:last-child) {
            border-right: 1px solid palette-color-level('grey', '15');
          }
        }
      }
      &-image {
        margin: auto;
      }
    }
  }

  .process-info-modal {
    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: palette-space-level('20');
    }
    table {
      th,
      td {
        padding: 8px;
        border: 1px solid palette-color-level('grey', 18);
        &:nth-child(n + 2) {
          text-align: center;
        }
      }
      thead {
        font-size: 11px;
        th {
          background-color: palette-color-level('grey', 10);
        }
      }
      tbody {
        font-size: 10px;
      }
      tfoot {
        font-size: 11px;
        font-weight: bold;
      }
    }
  }
}

/deep/ .picker-modal {
  position: fixed;
}
</style>
